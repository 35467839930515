<template>
  <div class="d-flex align-items-center min-vh-100 bg-image">
    <CContainer fluid>
      <CRow class="justify-content-center">
        <CCol md="6" sm="12">
          <CCard class="mx-4 mb-0 card-design">
            <CCardBody class="p-4">
              <a
                data-v-0c70a75a=""
                href="#/"
                aria-current="page"
                class="
                  c-header-brand
                  mx-3
                  router-link-exact-active router-link-active
                "
                target="_self"
              >
                <img
                  data-v-0c70a75a=""
                  src="img/logo/logo.png"
                  alt="Logo"
                  class=""
                  name="logo"
                  height="25"
                />
              </a>

              <form-wizard
                :start-index="0"
                ref="wizard"
                title=""
                subtitle="Welcome back"
                color="#7F30BF"
              >
                <tab-content
                  title="Personal details"
                  :before-change="genereateOtp"
                >
                  <ValidationObserver ref="step1_form">
                    <div v-show="mobile_show">
                      <ValidationProvider
                        vid="mobile_no"
                        name="mobile no"
                        v-slot="{ errors }"
                        rules="required|min:11"
                      >
                        <label class="sign-up-lable"
                          >Enter your mobile no (required)</label
                        >
                        <CInput
                          size="lg"
                          v-model="form_params.mobile_no"
                          v-mask="'+94#########'"
                          placeholder="(+94)-XXXXXXXXX"
                          :is-valid="checkValidInput(errors)"
                        />

                        <div v-if="errors[0]" class="wizard-error">
                          <span
                            v-for="(error, index) in errors"
                            v-bind:key="index"
                          >
                            {{ error }}
                          </span>
                        </div>
                      </ValidationProvider>
                    </div>
                  </ValidationObserver>

                  <CRow>
                    <CCol md="12">
                      <div class="text-center" v-show="opt_show">
                        <ValidationObserver ref="step1_checking_form">
                          <ValidationProvider
                            vid="otp"
                            name="otp"
                            v-slot="{ errors }"
                            rules="required"
                          >
                            <label class="sign-up-lable"
                              >Enter the code sent to
                              {{ form_params.mobile_no }}</label
                            >
                            <input type="hidden" v-model="form_params.otp" />
                            <!-- <v-otp-input
                              class="otp-text-feild"
                              inputClasses="otp-input"
                              :numInputs="4"
                              separator="-"
                              :shouldAutoFocus="true"
                              @on-complete="handleOnComplete"
                              @on-change="handleOnChange"
                            /> -->

                            <br />

                            <input
                              class="text-center otp-txt-box"
                              type="text"
                              v-mask="'#-#-#-#'"
                              v-model="otp_mask"
                              placeholder="-  -  -  -"
                            />

                            <div v-if="errors[0]" class="wizard-error">
                              <span
                                v-for="(error, index) in errors"
                                v-bind:key="index"
                              >
                                {{ error }}
                              </span>
                            </div>
                          </ValidationProvider>
                        </ValidationObserver>
                      </div>
                    </CCol>
                    <CCol class="pt-4" md="12">
                      <div class="text-white text-center" v-show="opt_show">
                        Time Remaining - {{ prettyTime }}
                      </div>
                      <div class="text-center">
                        <a v-show="opt_show" href="#" @click="resendOtp"
                          >Resend otp</a
                        >
                      </div>
                    </CCol>
                  </CRow>
                </tab-content>
                <tab-content
                  title="Additional Info"
                  :before-change="checkInfoValidity"
                >
                  <ValidationObserver ref="step2_form">
                    <div class="form-group">
                      <ValidationProvider
                        vid="first_name"
                        name="first name"
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <label class="sign-up-lable"
                          >Enter your first name (required)</label
                        >
                        <CInput
                          size="lg"
                          placeholder="First Name"
                          v-model="form_params.first_name"
                          :is-valid="checkValidInput(errors)"
                        />
                        <div v-if="errors[0]" class="wizard-error">
                          <span
                            v-for="(error, index) in errors"
                            v-bind:key="index"
                          >
                            {{ error }}
                          </span>
                        </div>
                      </ValidationProvider>
                    </div>

                    <div class="form-group">
                      <ValidationProvider
                        vid="last_name"
                        name="last name"
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <label class="sign-up-lable"
                          >Enter your last name (required)</label
                        >
                        <CInput
                          size="lg"
                          placeholder="Last Name"
                          v-model="form_params.last_name"
                          :is-valid="checkValidInput(errors)"
                        />
                        <div v-if="errors[0]" class="wizard-error">
                          <span
                            v-for="(error, index) in errors"
                            v-bind:key="index"
                          >
                            {{ error }}
                          </span>
                        </div>
                      </ValidationProvider>
                    </div>

                    <div class="form-group">
                      <ValidationProvider
                        vid="email"
                        name="email"
                        v-slot="{ errors }"
                        rules=""
                      >
                        <label class="sign-up-lable">Enter your Email</label>
                        <CInput
                          size="lg"
                          placeholder="Email"
                          v-model="form_params.email"
                          :is-valid="checkValidInput(errors)"
                        />
                        <div v-if="errors[0]" class="wizard-error">
                          <span
                            v-for="(error, index) in errors"
                            v-bind:key="index"
                          >
                            {{ error }}
                          </span>
                        </div>
                      </ValidationProvider>
                    </div>
                  </ValidationObserver>
                </tab-content>

                <template slot="footer" scope="props">
                  <div class="wizard-footer-left">
                    <!-- remove previous button -->
                    <!-- <wizard-button v-if="props.activeTabIndex > 0 && !props.isLastStep" @click.native="props.prevTab()" :style="props.fillButtonStyle">Previous</wizard-button> -->
                  </div>
                  <div class="wizard-footer-right" v-show="!isTimerRunning">
                    <wizard-button
                      @click.native="props.nextTab()"
                      class="wizard-footer-right finish-button"
                      :style="props.fillButtonStyle"
                      >{{ props.isLastStep ? "Done" : "Next" }}</wizard-button
                    >
                  </div>
                </template>
              </form-wizard>
              <CRow>
                <CCol md="12">
                  <div class="text-center text-color pt-2">
                    Don't have an account?
                    <router-link to="/register">Sign Up</router-link>
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import store from "../../store/store";
export default {
  name: "Register",
  data() {
    return {
      opt_show: false,
      mobile_show: true,
      vm: "",
      isTimerRunning: false,
      minutes: 2,
      secondes: 0,
      time: 120,
      timer: null,
      otp_mask: "",
      form_params: {
        mobile_no: "",
        first_name: "",
        last_name: "",
        email: "",
        otp: "",
      },
    };
  },
  mounted() {
    this.vm = this;
  },
  watch: {
    otp_mask: {
      handler: function (after, before) {
        let otpStr = this.otp_mask;
        if (otpStr.length == 7) {
          otpStr = otpStr.replace(/-/g, "");
          this.handleOnComplete(otpStr);
        }
      },
      deep: true,
    },
  },
  filters: {},
  computed: {
    prettyTime() {
      let time = this.time / 60;
      let minutes = parseInt(time);
      let secondes = Math.round((time - minutes) * 60);
      return minutes + ":" + secondes;
    },
  },
  methods: {
    ...mapActions("user", [
      "genereateCustomerOtp",
      "checkOtpValidity",
      "checkCustomerInfoValidity",
      "generateCustomerToken",
      "checkCustomerMobile",
      "resendOtpRequest",
    ]),
    resendOtp() {
      const params = {
        mobile_no: this.form_params.mobile_no,
      };
      this.resendOtpRequest(params).then((data) => {
        if (!data.data.error) {
          this.time = 120;
          this.start();
        } else {
        }
      });
    },
    checkInfoValidity() {
      const params = {
        mobile_no: this.form_params.mobile_no,
        email: this.form_params.email,
        first_name: this.form_params.first_name,
        last_name: this.form_params.last_name,
      };
      this.checkCustomerInfoValidity(params).then((data) => {
        if (!data.data.error) {
          this.vm.$refs.wizard.changeTab(1, 2);
        } else {
          this.$refs.step2_form.setErrors(data.data.error);
        }
      });
    },
    checkValidInput(error) {
      if (error.length) {
        return false;
      }
    },
    start() {
      this.isTimerRunning = true;
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.time > 0) {
            this.time--;
          } else {
            clearInterval(this.timer);
            this.reset();
          }
        }, 1000);
      }
    },
    stop() {
      this.isTimerRunning = false;
      clearInterval(this.timer);
      this.timer = null;
    },
    reset() {
      this.stop();
      this.time = 0;
      this.secondes = 0;
      this.minutes = 0;
      this.isTimerRunning = false;
      this.timer = null;
    },
    setTime(payload) {
      this.time = payload.minutes * 60 + payload.secondes;
    },
    genereateOtp() {
      const params = {
        mobile_no: this.form_params.mobile_no,
      };
      this.checkCustomerMobile(params).then((data) => {
        if (!data.data.error) {
          this.opt_show = true;
          this.mobile_show = false;
          this.start();
        } else {
          this.$refs.step1_form.setErrors(data.data.error);
        }
      });
    },
    stopTimer() {
      this.stop();
      this.isTimerRunning = false;
      clearInterval(this.timer);
      this.timer = null;
    },
    handleOnComplete(value) {
      this.form_params.otp = value;
      const params = {
        mobile_no: this.form_params.mobile_no,
        otp: this.form_params.otp,
      };
      this.checkOtpValidity(params).then((data) => {
        if (!data.data.error) {
          this.stop();
          this.vm.isTimerRunning = false;
          clearInterval(this.timer);
          this.vm.timer = null;

          const params = {
            mobile_no: this.form_params.mobile_no,
            refresh_required: true,
          };
          this.generateCustomerToken(params).then((data) => {
            if (!data.data.error) {
              this.opt_show = true;
              this.mobile_show = false;
              this.start();
            } else {
              this.$refs.step1_form.setErrors(data.data.error);
            }
          });
        } else {
          this.$refs.step1_checking_form.setErrors(data.data.error);
        }
      });
    },
    handleOnChange(value) {},
  },
};
</script>

<style>
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 14px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: "center";
}

.error {
  border: 1px solid red !important;
}

.wizard-progress-with-circle {
  display: none;
}

.wizard-nav.wizard-nav-pills {
  display: none;
}

.vue-form-wizard .category {
  font-size: 35px;
  font-weight: bold;
}

.vue-form-wizard .wizard-card-footer .wizard-footer-right {
  float: right;
  width: 100%;
}

.finish-button {
  padding: 18px !important;
}

.wizard-error {
  color: #d65a5a;
}

.form-control {
  color: #ffffff;
  background-color: #fff0;
}

/* .vue-form-wizard * {
    justify-content: center;
    display: grid;
} */
</style>

<style scoped>
.card-design {
  background-color: rgba(0, 0, 0, 0.822);
}

.bg-image {
  background-image: url("../../../public/img/bg_images/doodle.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.otp-text-feild {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  align-content: center !important;
  justify-content: center !important;
  align-items: center !important;
}

.text-color {
  color: #ffffff;
}

.sign-up-lable {
  font-size: 17px;
  color: #ffffff;
}

a {
  text-decoration: none;
  background-color: transparent;
  color: #b20dff;
}
</style>
